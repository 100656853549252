import React, { useRef, useEffect } from 'react'
import { T } from '../../typo'
import { s, colors } from '../../style'
import { WrapperSection } from '../../wrappers'
import Feature from '../Feature/Feature'
import { ScrollDots } from '../../elements'
import { useOnScreen } from '../../hooks'
import ScrollContainer from 'react-indiana-drag-scroll'

const Features = ({ theme, data }) => {
  const { features, language, slug_section, title } = data

  const refFeatures = useRef(null)
  const onScreen = useOnScreen(refFeatures, '0px')
  const headlineH2 = 'h2'

  useEffect(() => {
    setTimeout(() => refFeatures.current.scrollTo({ left: 1 }), 0)
  }, [])
  return (
    <>
      <WrapperSection id={slug_section}>
        <T
          d={32}
          m={32}
          o={0.16}
          bold
          condensed
          upper
          variant={headlineH2}
          extraCss={[sHeadline]}
          onScreen={onScreen}>
          {title}
        </T>
      </WrapperSection>
      <ScrollContainer
        nativeMobileScroll
        innerRef={refFeatures}
        css={[
          sFeaturesContainer,
          // { [s.md]: { width: features.length * 460 } },
          // features.length <= 3
          //   ? { [s.md]: { justifyContent: 'center' } }
          //   : { [s.md]: { marginLeft: 100 } },
        ]}>
        {features.map((feature, id) => (
          <Feature
            language={language}
            theme={theme}
            feature={feature}
            key={id}
            index={id}
            onScreen={onScreen}
          />
        ))}
      </ScrollContainer>
      <ScrollDots
        onScreen={onScreen}
        refElement={refFeatures}
        arr={features}
        extraCss={sDots(theme)}
      />
    </>
  )
}

const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    [s.sm_down]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
  activeDesktop: {
    [s.md]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
})

const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    textAlign: 'center',
    // marginBottom: '6rem',
  },
  [s.md]: {
    padding: '0 2rem',
    // marginBottom: '8rem'
  },
  transition: 'opacity 0.25s ease-out, transform 0.5s ease-out',
}

const sFeaturesContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '0rem 2rem 4rem' },
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}

export default Features
